import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import FileUpload from './pages/FileUpload';
import VideoPlayer from './pages/VideoPlayer';
import { ParentComponent } from './pages/ParentComponent';

function App() {
  return (
  <>
    <Routes>
      <Route path="/" element={<ParentComponent/>}/>
      <Route path="/video/:filename" element={<VideoPlayer/>}/>
    </Routes>
  </>

  );
}

export default App;
