import React, { useState, useEffect } from "react";
import FileUpload from "./FileUpload";
import VideoPlayer from "./VideoPlayer";
import axios from "axios";
import { Link } from "react-router-dom";

export function ParentComponent() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileList, setFileList] = useState([]);

  const handleFileSelect = (file) => {
    setSelectedFile(file);
  };

  const getData = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}listFiles`)
      .then(({ data }) => setFileList(data.files))
      .catch((error) => console.error("Error fetching file list:", error));
  };
  useEffect(() => {
    // Fetch the list of available files from the server

    getData();
  }, []);

  return (
    <div>
      <FileUpload onFileSelect={handleFileSelect} getData={getData} />
      <div>
        <h3>Available Files:</h3>
        <ul>
          {fileList?.map((filename, index) => (
            <li key={index}>
              <Link to={`/video/${filename}`}>{filename}</Link>
            </li>
          ))}
        </ul>
      </div>
      {selectedFile && <VideoPlayer filename={selectedFile} />}
    </div>
  );
}
