// VideoPlayer.js

import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

const VideoPlayer = () => {
    const {filename} = useParams();
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
    video.src = `${process.env.REACT_APP_BACKEND_URL}stream/${filename}`;
  }, [filename]);

  return (
    <div>
      <video controls ref={videoRef} style={{width:"80%"}}/>
    </div>
  );
};

export default VideoPlayer;
