// FileUpload.js

import axios from 'axios';
import React, { useState } from 'react';

const FileUpload = ({getData}) => {
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {

    setFile(e?.target?.files[0]);
  };

  const handleUpload = async () => {
    const formData = new FormData();
    formData.append('file', file);
    
    axios.post(`${process.env.REACT_APP_BACKEND_URL}upload`,formData).then(response=>{ 
        setFile(null)
        getData();
    }).catch(err=>console.error(err))

  };

  return (
    <div>
      <input type="file" onChange={handleFileChange}  />
      <button onClick={handleUpload}>Upload</button>
    </div>
  );
};

export default FileUpload;
